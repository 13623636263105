@import "../css/admin.css";
@import "~dropzone/dist/basic.css";
@import "~dropzone/dist/dropzone.css";
@import "~bootstrap4c-dropzone/dist/css/component-dropzone.css";

body {
    background-color: #3d3d3d;
}

.login_btn{
    width: 200px;
    margin-left: 50px;
    border-radius: 12px;
}

#login-column{
    background-color: rgba(0,116,198,0.2);
    border-radius: 12px;
    padding-bottom: 20px;
}

.login-column{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

@media (max-width: 520px) {
    .login-column{
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .login_btn{
        margin-left:0px;
    }
}

.action-change_target{
    color:  #00b449;
}
